/**
 * 常用的三方包都统一打在这里
 * 该文件谨慎添加！！！！
 */

/**
 * lib.js
 */
// 全局暴露 $ 和 jQuery 变量
require('expose-loader?$!expose-loader?jQuery!jquery');
// require('imports-loader?define=>false!lib/jquery/migrate/jquery-migrate'); // TODO remove
require('expose-loader?Clipboard!lib/clipboard/1.7.1/clipboard.min');
// 配置lazysizes库的expFactor参数，不能为1，会导致preload失效
window.lazySizesConfig = { expFactor: 1.1 };
require('lib/touchslide/1.1/touchslide.min'); 
require('lib/lazysizes/2.0.2/lazysizes');
require('lib/jquery-browser');   //TODO remove
require('lib/jquery-qrcode/jquery.qrcode');
// require('lib/jquery-touch-events/jquery.touch.events.min');
require('lib/jquery-base64/jquery.base64');
// require('lib/photo-swipe/photoswipe-from-dom');
require('lib/jquery-cookie/jquery.cookie');
// require('lib/highlight/jquery.highlight');
// require('lib/jquery-ocupload/1.1.2/ocupload');
require('lib/swiper/3.3/js/swiper.jquery');
// require('lib/select2/4.0.3/js/select2');
// require('lib/canvas-to-blob/canvas-to-blob');
require('lib/autocomplete/1.4.7/jquery.autocomplete');
// require('lib/cropper/2.3.4/js/cropper');
// require('expose-loader?IScroll!lib/iscroll/iscroll'); // support IScroll  //TODO remove 
// require('expose-loader?jWeixin!expose-loader?wx!lib/jweixin'); // support jWeixin & wx
// require('expose-loader?template!lib/art-template/3.0.3/template.min'); // support template
// require('expose-loader?moment!moment'); // support moment

/**
 * lib.css
 */
// require('lib/cropper/2.3.4/css/cropper');
// require('lib/select2/4.0.3/css/select2');
// require('lib/photo-swipe/css/default-skin.min');
// require('lib/photo-swipe/css/photoswipe.min');
require('lib/swiper/3.3/css/swiper');
