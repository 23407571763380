// jQuery.browser={};(function(){jQuery.browser.msie=false; jQuery.browser.version=0;if(navigator.userAgent.match(/MSIE ([0-9]+)./)){ jQuery.browser.msie=true;jQuery.browser.version=RegExp.$1;}})();



var userAgent = window.navigator.userAgent,
    // Useragent RegExp
    rwebkit = /(webkit)[ \/]([\w.]+)/,
    ropera = /(opera)(?:.*version)?[ \/]([\w.]+)/,
    rmsie = /(msie) ([\w.]+)/,
    rmozilla = /(mozilla)(?:.*? rv:([\w.]+))?/,
    browserMatch,
    ua = userAgent.toLowerCase();
jQuery.browser = {};

// Use of jQuery.browser is frowned upon.
// More details: http://docs.jquery.com/Utilities/jQuery.browser
function uaMatch(ua) {
  var match = rwebkit.exec( ua ) ||
  ropera.exec( ua ) ||
  rmsie.exec( ua ) ||
  ua.indexOf("compatible") < 0 && rmozilla.exec( ua ) ||
  [];
  return  { browser: match[1] || "", version: match[2] || "0" };
}

browserMatch = uaMatch( ua );
if ( browserMatch.browser ) {
  jQuery.browser[ browserMatch.browser ] = true;
  jQuery.browser.version = browserMatch.version;
}

// Deprecated, use jQuery.browser.webkit instead
if ( jQuery.browser.webkit ) {
  jQuery.browser.safari = true;
}

/**
 * 处理一些 2+ jquery 废弃的方法
 */
jQuery.fn.live = jQuery.fn.on;


/**
 * 解决隐私模式下，读取 localStorage 抛错
 */
jQuery.browser.storage = (function() {
  try {
    window.localStorage.setItem("____key", "value");
    window.localStorage.removeItem("____key");
    return true;
  } catch(e){
    return false;
  }
}());

/**
 * 临时处理 Object.assign
 */
if(!Object.assign) {
  Object.assign = jQuery.extend;
}